.react-datetime-picker {
  z-index: 99;
}
#ResourceListContainer {
  position: relative;
  overflow: overlay;
  border: 1px solid #e7e7e7;
}
#ResourceListContainerGrpList {
  position: relative;
  overflow: auto;
  max-height: 400px;
  min-height: 100px;
}

.productSeletionBarContainer {
  max-height: 220px;
  position: relative;
  overflow-y: scroll;
  border: 1px solid #e7e7e7;
  padding-bottom: 10px;
  padding-left: 5px;
}

#plusMinusButtonDiv {
  margin-top: 10px;
}

#minusDiv {
  margin-left: 5px;
}

.productSeletionBarSpanMain {
  padding: 5px;
}

.productSeletionBarSpan1 {
  margin: 10px 0 0 0;
}

.submitBtnText {
  float: left;
  padding: 5px;
}
/* .Polaris-ResourceList__CheckableButtonWrapper{
    margin-top: -36px;
} */
.Polaris-ResourceList__HeaderWrapper.Polaris-ResourceList__HeaderWrapper--hasSelect {
  display: none;
}
.table.bordered{
  width: 100%;
}
.thead-th {
  font-size: 16px;
}
.table-thead {
  margin-top: 50px;
  text-align: left;
}
.custom-margin-bottom {
  margin-bottom: 10px;
}

/* Automatic search */
.AutoMaticGroup{
  margin: -40px 10px 10px 39px;
}

button.Polaris-Button.AddTag {
  margin-left: 82%;
}
/* Automatic search */

/* ----------overlay--------------- */
.list-page-overlay {
  background: transparent;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 999;
  pointer-events: none;
}
.list-page-overlay-visible {
  display: block;
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: 999999;
  pointer-events: visible;
}
.loading-spinner {
  margin-top: 23%;
  margin-left: 25%;
  position: fixed;
}
.loading-modal-spinner {
  position: fixed;
  margin-top: 1%;
  margin-left: 20%;
}
.Polaris-Button__Text a {
  color: white;
  text-decoration: none;
}

/* Plan Upgrade Page */

.card__ {
  margin-bottom: 20px;
  text-align: center;
  background-color: white;
  max-width: 400px;
  height: 360px;
  position: relative;
  box-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* ribbon */

.ribbbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbbon::before,
.ribbbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #57c443;
}
.ribbbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #57c443;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbbon-top-left::before,
.ribbbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* ======= */

.plan__header {
  padding: 20px;
  height: 90px;
  background-color: #6371c7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.plan__header2 {
  padding: 20px;
  height: 110px;
  background-color: #086d2a;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.plan__heading {
  font-weight: 600;
  font-size: 24px;
  color: white;
}
.plan__price {
  font-size: 30px;
  color: white;
  padding: 10px;
}
.plan__price span {
  font-size: 16px;
}
.plan__features {
  font-size: 14px;
  text-align: left;
}
.plan__features li {
  padding: 5px 0;
}
.button__section {
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.upgrade__btn,
.subscribe__btn {
  padding: 10px 30px;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  background-color: #6371c7;
  text-transform: uppercase;
}
.upgrade__btn:hover {
  background-color: #3f4eae;
  color: white;
}
.subscribe__btn {
  display: inline-block;
  color: black;
  background-color: #f4f6f8;
  cursor: default;
}
.plan__details {
  padding: 0 20px;
}

.Polaris-DataTable__Table td:nth-child(3),
.Polaris-DataTable__Table th:nth-child(3) {
  background-color: white !important;
}
.Polaris-DataTable__TableRow:hover .Polaris-DataTable__Cell {
  background: white !important;
}
.ac__activeStatus {
  padding: 5px 10px;
  background-color: lightblue;
  border-radius: 2px;
}
.ac__inactiveStatus {
  padding: 5px 10px;
  background-color: lightgray;
  border-radius: 2px;
}
.Polaris-Page--fullWidth {
  max-width: none !important;
}
#plusDiv,
#minusDiv {
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  float: right;
}
.Polaris-ResourceItem__Container {
  align-items: center !important;
}


.ReactSwitchPlan{
  margin-left: 263px;
  margin-top: -52px;
  margin-bottom: 31px;
}

/* loader */
.loading__ {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.2);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loading__ p {
  font-size: 30px;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #5866c2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*  */
@media only screen and (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal {
    max-height: calc(100vh - 130px) !important;
  }
}
@media only screen and (max-width: 48.0525em) {
  .Polaris-Modal-Dialog__Modal {
    max-height: 85% !important;
  }
}
.ac__customerModalSearch {
  width: 98%;
  margin: 10px;
}
.ac__shouldShow {
  display: block;
}
.ac__doNotShow {
  display: none;
}
/* Date Time Picker */
.samplePicker {
  width: 400px;
  position: relative;
}
.DatePickerDiv{
  width: 100%;
  position: absolute;
  background: white;
  z-index: 9999;
  bottom: 100%;  
}



.samplePickerOptions_EditSubtotal{
  position: absolute;
  bottom: 100%;
  height: 200px;
  background-color: white;
  overflow-y: auto;
  z-index: 10000;
  cursor: pointer;
  right: 0rem;
  width: 150px;
  left: 17rem;
  border:1px solid #ddd;
}
.samplePickerOptions {
  /* position: absolute;
  bottom: 100%;
  right: 0;
  height: 200px;
  width: 120px;
  background-color: white;
  overflow-y: auto;
  z-index: 10000;
  cursor: pointer; */
  bottom: 82%;
  box-shadow: 0 0 3px #0000008a;
  cursor: pointer;
  height: 172px;
  overflow-y: auto;
  position: absolute;
  right: 23%;
  width: 120px;
  z-index: 10000;
  background: #fff;
}


.samplePickerOptions_Subtotal {

  bottom: 80%;
    box-shadow: 0 0 3px #0000008a;
    cursor: pointer;
    height: 172px;
    overflow-y: auto;
    position: absolute;
    right: 241px;
    width: 120px;
    z-index: 10000;
    background: #fff;

}
.samplePickerOption {
  border-bottom: 0.5px solid lightgray;
  padding: 5px;
  color: black;
  letter-spacing: 1px;
}
.testDivv {
  display: flex;
}
.dateTimePicker {
  display: flex;
}
/* Date Time Picker */

.ac__actionBar {
  padding: 0 4px;
}
.ac__groupListTable {
  border: none;
  width: 100%;
}
.ac__groupListTableCell {
  /* width: 33.3%; */
  padding: 10px;
  border: none;
  text-align: left;
}
.ac__groupListTableCell.ac__groupListTableCellWidth{
  min-width: 30%;
}
.ac__groupListSequence{
  width: 3%;
  /* cursor: grab;
  -webkit-user-drag: auto; */
}
.ac__groupListTableCellManage {
  text-align: center;
}
.Polaris-Breadcrumbs__Breadcrumb {margin: 0 !important}
.ActiveHeaderButton {
  box-shadow: 2px 2px 2px #888888;
}
.ac__variantBox {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 110px;
}
.ac__variantItemRow {
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0;
  padding: 5px;
  background-color: ghostwhite;
  border-radius: 5px;
}
.ac__variantItemRow > p {
  margin: 0 5px;
}
.ac__svgVariant {
  width: 40px;
  height: auto;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}
.Polaris-DataTable__Cell {
  padding: 0.6rem;
}
.Polaris-DataTable__TableRow > .Polaris-DataTable__Cell:last-child{
 /* position:absolute; 
   background: white;*/
   position:relative;
   right: 0;
   background: transparent;
   border: none;
   margin-top: 14px;
}

.disclaimer-popup{
  font-size: 16px;
  padding: 0 10px;
}
/* .disclaimer-popup-note{
  
} */
.disclaimer-popup-preference{
  padding: 10px 0px;
}
.disclaimer-popup-preference input{
  width: 30px;
  height: 25px;
  margin: 0 0 0 10px;
  text-align: center;
}
.disclaimer-popup-preference .required-fields{
  color: red;
}

.CreateGroup {
  margin: -40px 10px 10px 39px;
}
.AutoMaticGroup{
  margin: -40px 10px 10px 39px;
}
.Autocomplete {
  width: 186%;
}
button.Polaris-Button.AddTag {
  margin-left: 82%;
}


/* custom-error-border css */
.custom-error-border{
  border: 3px solid #e52e2e;
  border-radius: 10px;
  padding: 10px;
}

/* subtotoal btn css */
#SDbtn{
  display: flex;
  justify-content: center;
    /* width: 30%; */
  margin: -53px auto 50px;
}




/*  */
@media screen and (max-width: 600px) {
  .Autocomplete {

    width: 82%;
}
button.Polaris-Button.AddTag {
  position: relative;
  bottom: 55px;
}
}


@media screen and (max-width: 620px) {
  .samplePickerOptions_EditSubtotal{left: 7rem; }
      
    
  }
  
  
  @media screen and (max-width: 380px) {
  .samplePickerOptions_EditSubtotal{width: 105px;  left: 4rem;  }
      
  }


@media (max-width:920px) and  (min-width:280px){

.plan__price {font-size: 22px; color: white; padding: 10px; }
      
.ribbbon-top-left span {right: -2px; top: 17px;  transform: rotate(-45deg);}
    
.ribbbon span {position: absolute; display: block; width: 225px;  padding: 14px 0;background-color: #57c443;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); color: #fff;  font: 700 18px/1 "Lato", sans-serif;
text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);  text-transform: uppercase;text-align: center;
font-size: 14px;}

.ribbbon-top-left::after { bottom: 43px; left: 0;}
.ribbbon-top-left::before {top: 0px; right: 43px;}
.plan__header { padding: 20px; height: 98px;background-color: #6371c7;border-top-left-radius: 10px;
 border-top-right-radius: 10px;}
 .plan__header2 { padding: 20px; height: 98px;background-color: #086d2a;border-top-left-radius: 10px;
  border-top-right-radius: 10px;}
.plan__features li { padding: 3px 0;}
.plan__details {padding: 0 0px;}



  }
  

@media (max-width:920px) and  (min-width:528px){
.card__ {margin-bottom: 20px;text-align: center;background-color: white;max-width: 400px;height: 400px;
position: relative;   border-top-left-radius: 10px; border-top-right-radius: 10px;
box-shadow: var( --p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15) );
    }
    
    
}


@media (max-width:480px) and  (min-width:280px){
.card__ { text-align: center; background-color: white; max-width: 500px;height: 412px;position: relative;
border-top-left-radius: 10px;border-top-right-radius: 10px;margin: 0 -12px 20px -38px;
box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15) );
  }
    
  

}